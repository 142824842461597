import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Header from "./Header";
import "../../src/assets/stylesheets/application.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { SITE_TITLE } from "../lib/constants";

/**
 * shows the main layout
 * @param {import("react").ReactElement} children - shows main content
 * @param {string} [pageName=""] - identifies page used for custom css styling
 * @param {bool} [showHeader=true] - toggles the header visibility
 * @param {Element} [secondaryNav] - shows secondary navigation links in the header
 * @param {string} [siteTitle={process.env.SITE_TITLE}] - site title
 * @param {string} [siteDescription=""] - site description
 * @returns {any}
 */
const Layout = ({
  children,
  pageName = "",
  showHeader = true,
  secondaryNav,
  siteTitle = SITE_TITLE,
  siteDescription = "",
  isMap = false,
}) => {
  const { t } = useTranslation();

  let className = "";

  if (pageName.length) {
    className = className + " page-" + pageName;
  }

  return (
    <>
      <Helmet bodyAttributes={{ class: className }}>
        <title>{t("siteTitle")}</title>
      </Helmet>
      {showHeader && (
        <Header
          siteTitle={t(siteTitle)}
          siteDescription={t(siteDescription)}
          fadeIn={isMap}
        >
          {secondaryNav}
        </Header>
      )}
      <main>{children}</main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string,
  showHeader: PropTypes.bool,
  secondaryNav: PropTypes.element,
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  isMap: PropTypes.bool,
};

export default Layout;
