import React from "react";
import { NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/pro-regular-svg-icons";
import { useI18next } from "gatsby-plugin-react-i18next";

/*
Shows a drop down list for changing language locales
returns null when no languages provided
*/
const LanguageSelector = () => {
  const { changeLanguage, languages, language } = useI18next();

  const titleElement = (
    <span>
      <FontAwesomeIcon icon={faGlobe} className="mr-1" />
      {language}
    </span>
  );

  return languages.length === 1 ? null : (
    <NavDropdown title={titleElement} className="header-dropdown-link">
      {languages.map((lng) => (
        <NavDropdown.Item
          key={lng}
          onClick={(e) => {
            e.preventDefault();
            changeLanguage(lng);
          }}
        >
          {lng}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

export default LanguageSelector;
